$(document).ready(function () {

  $("#modal-call").iziModal({
      overlayColor: 'rgba(0, 0, 0, 0.2)',
      radius: 0,
      zindex: 1999,
      onClosed: function(){
        $('#modal-call input[name=phone]').val('');
        $('#modal-call .modal__action').show();
        $('#modal-call .modal__success').hide();
      }
  });

  $("#modal-review").iziModal({
    overlayColor: 'rgba(0, 0, 0, 0.2)',
    radius: 0,
    padding: 0,
    width: '100%',
    zindex: 19999,
    onClosed: function(){
      $('#modal-review form')[0].reset();
      $('#modal-review form').parsley().reset()
    }
  });

  $('#modal-review form').parsley();

  $('#modal-call input[name=phone]').mask('0 000 000 00 00', {
    clearIfNotMatch: true,
    onComplete: function(cep) {
      $('#modal-call').addClass('completed');
    },
    onChange: function(cep){
      if(cep.length < 15){
        $('#modal-call').removeClass('completed');
      }
    }
  });

  $("a[data-slide-to]").click(
    function()
    {
      var slide_to = $(this).data('slide-to');
      $("html, body").animate({ scrollTop: $($("body").find("section[data-slide-to='" + slide_to + "']")).offset().top }, "slow");
      return false;
    });
    //--------------- magnific-popup popup-img INIT ---------
    $('.popup-img-link')
        .magnificPopup({
            type: 'image',
            closeOnContentClick: true,
            closeBtnInside: false,
            fixedContentPos: true,
            mainClass: 'mfp-no-margins mfp-with-zoom', // class to remove default margin from left and right side
            image: {
                verticalFit: true
            },
            zoom: {
                enabled: true,
                duration: 0 // don't foget to change the duration also in CSS
            },
            callbacks: {
                beforeOpen: function () {
                    //console.log('Start of popup initialization');
                },
                open: function () {
                    // Will fire when this exact popup is opened
                    // this - is Magnific Popup object
                },
                close: function () {
                    // Will fire when popup is closed
                }
            }
        });
});

var lastScrPos, curScrPos;

var hdrHiding = function (csp, lsp) {
    "use strict";
    var hdr = $('.hdr');
    if (hdr.length) {
        if (isMobile.phone || window.innerWidth < 768) {
            hdr.removeClass('hdr_fixed')
                .removeClass('hdr_visible');
            if (csp > 50) {
                hdr.removeClass('hdr_hidden')
                    .addClass('hdr_visible');
            }
            if (csp > lsp) {
                //console.log('downscroll');
                if (csp > 50) {
                    hdr.addClass('hdr_hidden')
                        .removeClass('hdr_visible');
                }
            } else {
                //console.log('upscroll');
                if (csp <= 50) {
                    //console.log('csp <= 50');
                    hdr.removeClass('hdr_hidden')
                        .removeClass('hdr_visible');
                }
                if (csp > 50) {
                    //console.log('csp > 50');
                    if (hdr.hasClass('hdr_hidden')) {
                        hdr.removeClass('hdr_hidden')
                            .addClass('hdr_visible');
                    }
                }
            }
        }
        if (window.innerWidth >= 768) {
            $('.uh-logo-scrollTop').css({
                left: hdr.find('.uh-logo')[0].getBoundingClientRect().left + 'px'
            });
            if (csp > 50) {
                hdr.addClass('hdr_fixed');
            }
            else if (csp <= 50) {
                hdr.removeClass('hdr_fixed')
                    .addClass('hdr_visible');
            }
        }
    }

};
var scrTopBtn = function (csp) {
    var btn = $('.ftr-scrollTop-button');
    if (csp > 600) {
        btn.addClass('ftr-scrollTop-button_visible');
    }
    else if (csp <= 600) {
        btn.removeClass('ftr-scrollTop-button_visible');
    }
};
var body_isMob = function () {
    "use strict";
    if (!isMobile.any || window.innerWidth > 1024) {
        $('body').attr('class', '');
    }
    else if (isMobile.tablet || window.innerWidth >= 768) {
        $('body').attr('class', 'tablet');
    }
    else if (isMobile.phone || window.innerWidth < 768) {
        $('body').attr('class', 'phone');
    }
};
var desProSlider = function () {
    var slider = $('.design-process__slider');
    var thumbsUnit = $('.design-process__thumbs__unit');
    //--------- design-process__slider  Actions ---------------------------------------------
    if (slider.length > 0) {
        thumbsUnit.eq(slider[0].swiper.activeIndex)
            .addClass('design-process__thumbs__unit_cur');

        //var attrActiveBg = thumbsUnit.eq(slider[0].swiper.activeIndex).find('span.icon').attr('data-active-bg-url').toString();
        //thumbsUnit.eq(slider[0].swiper.activeIndex).find('span.icon')
        //    .css({backgroundImage: 'url(' + attrActiveBg + ')'});

        slider[0].swiper
            .on('slideChangeStart', function (s, e) {
                var prev = thumbsUnit.eq(s.previousIndex),
                    active = thumbsUnit.eq(s.activeIndex);

                prev.removeClass('design-process__thumbs__unit_cur');
                //prev.find('span.icon')
                //    .css({backgroundImage: 'url(' + prev.find('span.icon').attr('data-bg-url') + ')'});
                setTimeout(function () {
                    active.addClass('design-process__thumbs__unit_cur');
                    //active.find('span.icon')
                    //    .css({backgroundImage: 'url(' + active.find('span.icon').attr('data-active-bg-url') + ')'});
                }, 0);
            });

        thumbsUnit
            .on('click', function () {
                slider[0].swiper.slideTo($(this).index());
            });
    }
};
var isRetina = function () {
    return ((window.matchMedia && (window.matchMedia('only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx), only screen and (min-resolution: 75.6dpcm)')
            .matches || window.matchMedia('only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2/1), only screen and (min--moz-device-pixel-ratio: 2), only screen and (min-device-pixel-ratio: 2)')
            .matches)) || (window.devicePixelRatio && window.devicePixelRatio >= 2)) && /(iPad|iPhone|iPod)/g.test(navigator.userAgent);
};
var BrowserDetect = {
    init: function () {
        this.browser = this.searchString(this.dataBrowser) || "Other";
        this.version = this.searchVersion(navigator.userAgent) || this.searchVersion(navigator.appVersion) || "Unknown";
    },
    searchString: function (data) {
        for (var i = 0; i < data.length; i++) {
            var dataString = data[i].string;
            this.versionSearchString = data[i].subString;

            if (dataString.indexOf(data[i].subString) !== -1) {
                return data[i].identity;
            }
        }
    },
    searchVersion: function (dataString) {
        var index = dataString.indexOf(this.versionSearchString);
        if (index === -1) {
            return;
        }

        var rv = dataString.indexOf("rv:");
        if (this.versionSearchString === "Trident" && rv !== -1) {
            return parseFloat(dataString.substring(rv + 3));
        } else {
            return parseFloat(dataString.substring(index + this.versionSearchString.length + 1));
        }
    },
    dataBrowser: [
        {string: navigator.userAgent, subString: "Edge", identity: "Edge"},
        {string: navigator.userAgent, subString: "MSIE", identity: "Explorer"},
        {string: navigator.userAgent, subString: "Trident", identity: "Explorer"},
        {string: navigator.userAgent, subString: "Firefox", identity: "Firefox"},
        {string: navigator.userAgent, subString: "Opera", identity: "Opera"},
        {string: navigator.userAgent, subString: "OPR", identity: "Opera"},

        {string: navigator.userAgent, subString: "Chrome", identity: "Chrome"},
        {string: navigator.userAgent, subString: "Safari", identity: "Safari"}
    ]
};
var fbResize = function () {
    $(".fb-page").attr("data-width", $('.ftr-fb-block').width());
    setTimeout(function () {
        FB.XFBML.parse();
    }, 50);
};
var fbInit = function () {
    $(".fb-page").attr("data-width", $('.ftr-fb-block').width());
    (function (d, s, id) {
        var js, fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) return;
        js = d.createElement(s);
        js.id = id;
        js.src = "//connect.facebook.net/ru_RU/sdk.js#xfbml=1&version=v2.5";
        fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'facebook-jssdk'));
};
// var projectDownload = function () {
//     var slider = $('.project-task-result__slider');
//     var download = $('.project-task-result__slider__download');
//
//     var cycle = setTimeout(function checkCycle() {
//
//         if (slider[0].swiper !== undefined) {
//             if (download.length) {
//                 var posTop = (slider[0].swiper.height - download.find('img').clientHeight).toFixed(0) - 2;
//
//                 if (window.innerWidth >= 1024) {
//                     download.css({top: posTop + 'px'});
//                 }
//                 if (window.innerWidth < 1024) {
//                     download.css({top: "auto"});
//                 }
//             }
//             download.removeClass('state--hide');
//             clearInterval(cycle);
//         }
//         else {
//             cycle = setTimeout(checkCycle, 100);
//         }
//
//     }, 100);
//
//
// };

$(function () {
    "use strict";
    curScrPos = window.pageYOffset || document.documentElement.scrollTop;
    lastScrPos = curScrPos;
    BrowserDetect.init();

    if (BrowserDetect.browser == 'Edge') {
        $('html').addClass('ie')
            .addClass('edge');
    }
    else if (BrowserDetect.browser == 'Explorer') {
        $('html').addClass('ie');
        if (BrowserDetect.version > 9) {
            $('html').addClass('ie-10');
        }

    }

    if (isMobile.apple.device) {
        $('html').addClass('ios');
    }

    hdrHiding(curScrPos, lastScrPos);
    scrTopBtn(curScrPos);
    fbInit();

    //--------------- HTML Visibility INIT ---------
    $('html').addClass('loaded');

    //--------------- feedback EVENTS ---------
    $(document)
        .on('click', '.field__label', function (e) {
            var $this = $(this),
                _this = this;
            var field = $this.closest('.field');
            e.preventDefault();
            if (!field.hasClass('filled')) {
                field.addClass('filled');
                field.find('.field__input').focus();
            }
        });
    $(document)
        .on('focus', '.field__input', function (e) {
            var $this = $(this),
                _this = this;
            var field = $this.closest('.field');
            e.preventDefault();
            if (!field.hasClass('filled')) {
                field.addClass('filled');
            }
        })
        .on('focusout', '.field__input', function (e) {
            var $this = $(this),
                _this = this;
            var field = $this.closest('.field');
            e.preventDefault();
            if ($this.val() === '') {
                field.removeClass('filled');
            }
        });

    //--------------- reviews EVENTS ---------
    $(document)
        .on('click', '.reviews__bubble__full-ref', function (e) {
            var $this = $(this),
                _this = this;
            var unit = $(this).closest('.reviews__unit');
            e.preventDefault();
            $('body')
                .addClass('fixed reviews_show')
                .append('' +
                    '<div class="reviews__body-wrap">' +
                    '   <span class="reviews__body-wrap__close">' +
                    '       <svg preserveAspectRatio="xMinYMin meet" viewBox="0 0 39 39" class="svg-icon svg-icon-close">' +
                    '           <use xlink:href="#svg-icon-close"></use>' +
                    '       </svg>' +
                    '   </span>' +
                    '   <div class="reviews__cnt">' +
                    '       <h1 class="reviews__cnt__title">Отзывы клиента</h1>' +
                    '   </div>' +
                    '   <a href="/agentstvo/reviews" class="reviews__body-wrap__other">Смотреть отзывы других клиентов</a>' +
                    '</div>');
            $('.reviews__body-wrap .reviews__cnt')
                .append(unit.clone());
            $('.reviews__body-wrap .reviews__bubble__text_q').remove();
            if (window.innerWidth >= 768) {
                $('.reviews__body-wrap .reviews__cnt')
                    .css({marginTop: (parseInt($('.reviews__body-wrap .reviews__cnt').height()) / 2) * (-1) + 'px'});
            }
            if (window.innerWidth < 768) {
                $('.reviews__body-wrap')
                    .mCustomScrollbar({
                        theme: 'dark-thin'
                    });
            }
        });
    $(document)
        .on('click', '.reviews__body-wrap__close', function (e) {
            var $this = $(this),
                _this = this;
            var wrap = $(this).closest('.reviews__body-wrap');
            e.preventDefault();
            if ($('body').hasClass('reviews_show')) {
                $('body')
                    .removeClass('fixed')
                    .removeClass('reviews_show');
                $('.reviews__body-wrap').remove();
            }
        });


    $(document)
        .on('click', '.uh-logo-scrollTop', function (e) {
            $("body,html").animate({"scrollTop": 0}, window.pageYOffset / 2);
        });
    $(document)
        .on('click', '.ftr-scrollTop-button', function (e) {
            $("body,html").animate({"scrollTop": 0}, window.pageYOffset / 2);
        });
    $(document)
        .on('click', '.clients__unit a', function (e) {
            e.preventDefault();
        });
    $(document)
        .on('click', '.hdr-nav__toggle-btn', function (e) {
            var $this = $(this),
                $body = $('body'),
                $hdr = $('.hdr'),
                $hdrLt = $('.hdr-nav__lt');

            if (!$this.hasClass('open')) {
                $this
                    .addClass('open');
                $body
                    .addClass('open-hdr-nav');
                $hdr
                    .addClass('open-hdr-nav');
                $hdrLt
                    .addClass('open');
            }
            else if ($this.hasClass('open')) {
                $this
                    .removeClass('open');
                $body
                    .removeClass('open-hdr-nav');
                $hdr
                    .removeClass('open-hdr-nav');
                $hdrLt
                    .removeClass('open');
            }
        });

    //--------------- slider INIT ---------
    $('.reSlider').reSlider();
    setTimeout(function () {
        desProSlider();
    }, 0);

    //--------------- mCustomScrollbar INIT ---------
    $('.mCustomScrollbar')
        .mCustomScrollbar({
            theme: 'dark-thin'
        });

    //--------------- project-task EVENTS ---------
    //projectDownload();
    $(document)
        .on('click', '.project-task-process__more', function (e) {
            var $this = $(this),
                _this = this;
            var ptp = $('.project-task-process');
            var ptp_f = $('.project-task-process__footer');
            e.preventDefault();
            ptp.addClass('open');
            ptp_f.remove();
            $this.remove();
        });

    //--------------- art-list EVENTS ---------
    if ($('.art-list').length) {
        (function (window) {
            "use strict";
            var artLt = $('.art-list');
            var artLt_Unit = null;
            var lastCount,
                artLt_maxH,
                artLt_unitStep,
                artLt_lastUnit;
            var artLt_init = function () {
                artLt_Unit = $('.art-list__unit');
                artLt_unitStep = parseInt(artLt.attr('data-unit-step'), 10);
                artLt_lastUnit = parseInt(artLt.attr('data-last-unit'), 10);
                artLt_maxH = 0;

                for (var j = 0; j < artLt_lastUnit; j++) {
                    if (artLt_Unit[j]) {
                        artLt_maxH = artLt_maxH + $(artLt_Unit[j]).innerHeight();
                    }
                }
                setTimeout(function () {
                    artLt.css({maxHeight: artLt_maxH + 'px'});
                    artLt.attr('data-max-height', artLt_maxH);
                }, 0);
            };
            artLt_init();
            $(window).on('resize', function () {
                artLt_init();
            });
            var artLt_more = function () {
                artLt_maxH = parseInt(artLt.attr('data-max-height'), 10);
                artLt_unitStep = parseInt(artLt.attr('data-unit-step'), 10);
                artLt_lastUnit = parseInt(artLt.attr('data-last-unit'), 10);
                lastCount = 0;
                for (var j = artLt_lastUnit; j < artLt_lastUnit + artLt_unitStep; j++) {
                    if (artLt_Unit[j]) {
                        lastCount++;
                        artLt_maxH = artLt_maxH + $(artLt_Unit[j]).innerHeight();
                    }
                }
                setTimeout(function () {
                    artLt.css({maxHeight: artLt_maxH + 'px'});
                    artLt.attr('data-max-height', artLt_maxH);
                    artLt.attr('data-last-unit', artLt_lastUnit + lastCount);
                }, 0);
            };
            window.publications = {
                init: artLt_init,
                more: artLt_more
            }
        })(window);
    }

    //--------------- vacancy EVENTS ---------
    $(document)
        .on('click', '.vacancy__btn', function (e) {
            var $this = $(this),
                _this = this;
            var unit = $this.closest('.vacancy__unit');
            var form = unit.find('.feedback');
            e.preventDefault();
            $this.addClass('hidden');
            form.addClass('open');
        });
    $(document)
        .on('click', '.feedback__close', function (e) {
            var $this = $(this),
                _this = this;
            var form = $this.closest('.feedback');
            var unit = $this.closest('.vacancy__unit');
            var unitBtn = unit.find('.vacancy__btn');
            e.preventDefault();
            if (form.hasClass('open')) {
                form.removeClass('open');
            }
            if (unitBtn.hasClass('hidden')) {
                unitBtn.removeClass('hidden');
            }
        });

    //--------------- contacts EVENTS ---------
    $(document)
        .on('click', '.how-to-get__show', function (e) {
            var $this = $(this),
                _this = this;
            if (window.innerWidth >= 320) {
                e.preventDefault();
                $('.how-to-get').addClass('how-to-get_open');
                setTimeout(function () {
                    $('.contacts-cell').addClass('hovered');
                }, 150)
            }
        });
    $(document)
        .on('click', '.how-to-get__close', function (e) {
            var $this = $(this),
                _this = this;
            e.preventDefault();
            $('.how-to-get').removeClass('how-to-get_open');
            setTimeout(function () {
                $('.contacts-cell').removeClass('hovered');
            }, 150)
        });

    //--------------- a-href window.location event ---------
    $(document)
        .on('click', 'a', function (e) {
            var _this = this;
            var $this = $(_this);
            var href = $this.attr('href');
            var reLoc = $this.attr('data-reloc');
            if (reLoc == 'true') {
                e.stopImmediatePropagation();
                e.preventDefault();
                if (href !== undefined) {
                    if (e.which == 2) {
                        window.open(window.location.origin + href.toString(), '_blank');
                    }
                    else {
                        $('html').addClass('relocation');
                        setTimeout(function () {
                            window.location.href = window.location.origin + href.toString();
                        }, 300);
                    }
                }
            }
        });
});

$(window).on('resize', function () {
    //console.log('resize');
    curScrPos = window.pageYOffset || document.documentElement.scrollTop;
    lastScrPos = curScrPos;

    hdrHiding(curScrPos, lastScrPos);
    scrTopBtn(curScrPos);
    setTimeout(function () {
        desProSlider();
        //projectDownload();
    }, 0);
    fbResize();

});

$(window).on('scroll', function () {
    //console.log('scroll');
    curScrPos = window.pageYOffset || document.documentElement.scrollTop;

    hdrHiding(curScrPos, lastScrPos);
    scrTopBtn(curScrPos);

    lastScrPos = curScrPos;
});


